import axios from 'axios'

const state = () => ({
  // todo
})

const getters = {
  // todo
}

const mutations = {
  // todo
}

const actions = {
  /**
   * Send OTP
   * @param {S} context
   * @param {*} credentials
   * @returns
   */
  async sendOTP(context, credentials) {
    try {
      // console.log(context, credentials)
      const params = {
        vender: 'allianz',
        mobile: credentials.phone,
      }

      let res = await axios.post(process.env.VUE_APP_NOTIFICATION_SEND_OTP, params)
      if (res.status === 200 && res.data.isSuccess) {
        console.log('Response API Send OTP ::', res)
        return res.data
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  async verifyOTP(context, credentials) {
    try {
      // console.log(context, credentials)
      const params = {
        vender: 'allianz',
        mobile: credentials.phone,
        otp: credentials.otp,
      }

      let res = await axios.post(process.env.VUE_APP_NOTIFICATION_VERIFY_OTP, params)
      if (res.status === 200) {
        console.log('Response API Verify OTP ::', res)
        return res.data
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
