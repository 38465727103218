import axios from 'axios'

const state = () => ({
  // todo
})

const getters = {
  // todo
}

const mutations = {
  // todo
}

const actions = {
  async getVerifyCustomer(context, credentials) {
    try {
      console.log(context, credentials)
      let res = await axios.post(process.env.VUE_APP_TTB_GET_CUSTOMERS, credentials)
      console.log('Response API Get Verify Customer ::', res)

      if (res.status === 200) {
        if (res.data.code === 4001) {
          return false
        } else {
          return res.data
        }
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  async getVerifyDOPA(context, credentials) {
    try {
      console.log(context, credentials)
      let res = await axios.post(process.env.VUE_APP_CREATE_RM_CHECK_DOPA, credentials)
      console.log('Response API Get Verify DOPA ::', res)

      if (res.status === 200) {
        if (res.data.code === 4001) {
          return false
        } else {
          return res.data.data
        }
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  async createNewCustomer(context, credentials) {
    try {
      console.log(context, credentials)
      let res = await axios.post(process.env.VUE_APP_CREATE_RM, credentials)
      console.log('Response API create new customer ::', res)

      if (res.status === 200) {
        return res.data.data
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get title
  async getTitle() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_TITLE)
      console.log('Response API Get Title ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get nationality
  async getNationality() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_NATIONALITY)
      console.log('Response API Get Nationality ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get business type
  async getBusinessType() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_BUSINESS_TYPE)
      console.log('Response API Get BusinessType ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get ocupation
  async getOcupation() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_OCCUPATION)
      console.log('Response API Get Ocupation ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get salary
  async getSalary() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_SALARY)
      console.log('Response API Get Salary ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get source of income
  async getSourceOfIncome() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_SOURCE_OF_INCOME)
      console.log('Response API Get SourceOfIncome ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get country of income
  async getCountryOfIncome() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_COUNTRY_OF_INCOME)
      console.log('Response API Get CountryOfIncome ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get ecucation
  async getEducation() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_EDUCATION)
      console.log('Response API Get CountryOfIncome ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get marital status
  async getMaritalStatus() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_MARITAL_STATUS)
      console.log('Response API Get CountryOfIncome ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  // get customer type
  async getCustomerType() {
    try {
      let res = await axios.get(process.env.VUE_APP_CREATE_RM_CUSTOMER_TYPE)
      console.log('Response API Get CountryOfIncome ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const list = []
        const items = res.data.data

        items.forEach((element) => {
          list.push({
            id: element.cl_code,
            name: element.cl_desc1,
            name_eng: element.cl_desc2,
          })
        })

        return list
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },

  async getAddress(context, credentials) {
    console.log(context, credentials)
    try {
      let res = await axios.get(
        `${process.env.VUE_APP_CREATE_RM_GET_ADDRESS}?province=${credentials.province}&district=${credentials.district}&sub_district=${credentials.sub_district}&postcode=${credentials.postcode}`
      )
      console.log('Response API Get Address ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        const items = res.data.data

        return items
      }
    } catch (err) {
      console.error(err.response.data.message)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
