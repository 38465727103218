import axios from 'axios'

/**
 * initial state
 */
const state = () => ({})

/**
 * getters
 */
const getters = {
  // todo
}

/**
 * mutations
 */
const mutations = {
  // todo
}

/**
 * actions
 */
const actions = {
  /**
   * Get Products
   */

  // eslint-disable-next-line no-unused-vars
  getProductLists(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_RENEWAL_GET_PRODUCT_LISTS)
        .then((response) => {
          console.log(response)
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  },

  /**
   * Get Lists
   */
  getQuotationLists(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API renewal list params =>', credentials)
      axios
        .post(process.env.VUE_APP_RENEWAL_LISTS, credentials)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * Get Detail
   */
  getQuotationDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API get renewal detail params =>', credentials)
      axios
        .get(process.env.VUE_APP_RENEWAL_GET_DETAIL + `/${credentials.id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * Send link
   */
  clickSendLink(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API send link params =>', credentials)
      axios
        .post(process.env.VUE_APP_RENEWAL_SEND_LINK + `/${credentials.id}`, {
          email: credentials.email,
          phone: credentials.phone,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * Change payment status
   */
  changePaymentStatus(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API update payment status params =>', credentials)
      axios
        .get(process.env.VUE_APP_RENEWAL_UPDATE_PAYMENT + `/${credentials.id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * Check payment status
   */
  checkPaymentStatus(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API check payment status params =>', credentials)
      axios
        .get(process.env.VUE_APP_RENEWAL_CHECK_PAYMENT + `/${credentials.id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * Update note
   */
  updateNote(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API update note params =>', credentials)
      const body = {
        hash_id: credentials.id,
        type: credentials.type,
        note: credentials.text,
      }
      axios
        .post(process.env.VUE_APP_RENEWAL_UPDATE_NOTE, body)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
