import Vue from 'vue'
import Vuex from 'vuex'
import staff from './modules/staff'
import productSme from './modules/productSme'
import productSme360 from './modules/productSme360'
import productKiosk from './modules/productKiosk'
import address from './modules/address'
import smeFireInsurance from './modules/smeFireInsurance'
import notification from './modules/notification'
import ttb from './modules/ttb'
import renewal from './modules/renewal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    staff,
    productSme,
    productSme360,
    productKiosk,
    address,
    smeFireInsurance,
    notification,
    ttb,
    renewal,
  },
  strict: process.env.NODE_ENV !== 'production',
})
