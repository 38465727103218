import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function load(component) {
  return () => import(`@/components/views/${component}.vue`)
}

function loadSmeChild(component) {
  return () => import(`@/components/product/ttb-sme/${component}.vue`)
}

function loadKioskChild(component) {
  return () => import(`@/components/product/ttb-kiosk/${component}.vue`)
}

function loadSme360Child(component) {
  return () => import(`@/components/product/ttb-360/${component}.vue`)
}

function loadNonpackageChild(component) {
  return () => import(`@/components/product/nonpackage/${component}.vue`)
}

function loadPublicCustomerInfo(component) {
  return () => import(`@/components/direct/customer-info/${component}.vue`)
}

function loadRenewalChild(component) {
  return () => import(`@/components/renewal-menu/${component}.vue`)
}

const routes = [
  // Authentication landing page
  {
    name: 'authentication',
    path: '/authentication',
    component: load('Authentication'),
    props: (route) => ({ query: route.query.code }),
    meta: {
      requiresVisitor: true,
    },
  },
  // Create new custom: Personal information page
  {
    name: 'createNewCustomer',
    path: '/create-rm/:packageName/customer-information/:smeFireInsuranceId',
    component: load('CreateNewCustomer'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  // Create new custom: Address information page
  {
    name: 'createNewCustomerAddress',
    path: '/create-rm/:packageName/address-information/:smeFireInsuranceId',
    component: load('CreateNewCustomerAddress'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  // History view page
  {
    name: 'history',
    path: '/history',
    component: load('History'),
    meta: {
      requiresAuth: true,
    },
  },
  // Log-in with ttb SSO page
  {
    name: 'login',
    path: '/',
    component: load('Login'),
    meta: {
      requiresVisitor: true,
    },
  },
  // Log-in with username and password page
  {
    name: 'loginStaffOnly',
    path: '/staff-aagi/login',
    component: load('LoginStaff'),
    meta: {
      requiresVisitor: true,
    },
  },
  // Log-out page
  {
    name: 'logout',
    path: '/logout',
    component: load('Logout'),
    props: true,
    meta: {
      requiresVisitor: true,
    },
  },
  // Non-package product
  {
    name: 'nonpackage',
    path: '/nonpackage',
    component: load('Nonpackage'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'nonpackage.planInfo',
        path: 'planInfo',
        component: loadNonpackageChild('PlanInfo'),
      },
      {
        name: 'nonpackage.thankyou',
        path: 'thankyou',
        component: loadNonpackageChild('Thankyou'),
      },
    ],
  },
  // Payment page
  {
    name: 'payment',
    path: '/payment/:packageType/:quotationId',
    component: load('Payment'),
    props: true,
  },
  // Payment renewal
  {
    name: 'renew.payment',
    path: '/renew/quotation-payment/:id',
    props: true,
    component: loadRenewalChild('PaymentPage'),
  },
  // Public customer information form
  {
    name: 'publicCustomerInfo',
    path: '/quotation',
    component: load('PublicCustomerInfo'),
    meta: {
      requiresVisitor: true,
    },
    children: [
      {
        name: 'publicCustomerInfo.customerInfo',
        path: ':smeFireInsuranceId',
        component: loadPublicCustomerInfo('CustomerInfo'),
        props: true,
      },
      {
        name: 'publicCustomerInfo.thankyou',
        path: 'thankyou/:packageType',
        component: loadPublicCustomerInfo('Thankyou'),
        props: true,
      },
    ],
  },
  // Product view page
  {
    name: 'product',
    path: '/product',
    component: load('Product'),
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
  },
  // Quotation information view page
  {
    name: 'quotationView',
    path: '/quotation-view/:smeFireInsuranceId',
    component: load('QuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
  },
  // Quotation information edit page
  {
    name: 'quotationEdit',
    path: '/quotation-edit/:smeFireInsuranceId',
    component: load('QuotationEdit'),
    props: true,
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
  },
  // Renewal page
  {
    name: 'renewal',
    path: '/renew',
    component: load('RenewalView'),
    // props: true,
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
    children: [
      {
        name: 'renew.history',
        path: 'history',
        component: loadRenewalChild('HistoryPage'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        name: 'renew.quotation',
        path: 'quotation-view/:id',
        props: true,
        component: loadRenewalChild('QuotationViewPage'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
    ],
  },

  // Report page
  {
    name: 'report',
    path: '/report',
    component: load('Report'),
    // props: true,
    meta: {
      requiresAuth: true,
      isSellable: false,
    },
  },
  // ttb sme product
  {
    name: 'sme',
    path: '/ttb-sme',
    component: load('Sme'),
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
    children: [
      {
        path: 'preview',
        name: 'sme.preview',
        component: loadSmeChild('Preview'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'buildinginfo',
        name: 'sme.buildinginfo',
        component: loadSmeChild('BuildingInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'planinfo',
        name: 'sme.planinfo',
        component: loadSmeChild('PlanInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'customerinfo/:smeFireInsuranceId',
        name: 'sme.customerinfo',
        component: loadSmeChild('CustomerInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'addressinfo/:smeFireInsuranceId',
        name: 'sme.addressinfo',
        component: loadSmeChild('AddressInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'shadow/:smeFireInsuranceId',
        name: 'sme.shadow',
        component: loadSmeChild('ShadowInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'confirmation/:smeFireInsuranceId',
        name: 'sme.confirmation',
        component: loadSmeChild('Confirmation'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
    ],
  },
  // kiosk
  {
    name: 'kiosk',
    path: '/ttb-kiosk',
    component: load('Kiosk'),
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
    children: [
      {
        path: 'preview',
        name: 'kiosk.preview',
        component: loadKioskChild('Preview'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'buildinginfo',
        name: 'kiosk.buildinginfo',
        component: loadKioskChild('BuildingInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'planinfo',
        name: 'kiosk.planinfo',
        component: loadKioskChild('PlanInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'customerinfo/:smeFireInsuranceId',
        name: 'kiosk.customerinfo',
        component: loadKioskChild('CustomerInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'addressinfo/:smeFireInsuranceId',
        name: 'kiosk.addressinfo',
        component: loadKioskChild('AddressInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'shadow/:smeFireInsuranceId',
        name: 'kiosk.shadow',
        component: loadKioskChild('ShadowInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'confirmation/:smeFireInsuranceId',
        name: 'kiosk.confirmation',
        component: loadKioskChild('Confirmation'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
    ],
  },
  // Biz360 product
  {
    name: 'sme360',
    path: '/ttb-biz-360',
    component: load('Sme360'),
    meta: {
      requiresAuth: true,
      isSellable: true,
    },
    children: [
      {
        path: 'preview',
        name: 'sme360.preview',
        component: loadSme360Child('Preview'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'buildinginfo',
        name: 'sme360.buildinginfo',
        component: loadSme360Child('BuildingInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'planinfo',
        name: 'sme360.planinfo',
        component: loadSme360Child('PlanInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
      },
      {
        path: 'customerinfo/:smeFireInsuranceId',
        name: 'sme360.customerinfo',
        component: loadSme360Child('CustomerInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'addressinfo/:smeFireInsuranceId',
        name: 'sme360.addressinfo',
        component: loadSme360Child('AddressInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'shadow/:smeFireInsuranceId',
        name: 'sme360.shadow',
        component: loadSme360Child('ShadowInfo'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
      {
        path: 'confirmation/:smeFireInsuranceId',
        name: 'sme360.confirmation',
        component: loadSme360Child('Confirmation'),
        meta: {
          requiresAuth: true,
          isSellable: true,
        },
        props: true,
      },
    ],
  },
  // Error handling page
  {
    name: 'NotFoundPage',
    path: '/:catchAll(.*)',
    component: load('NotFoundPage'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
})

/**
 * Check Authentication
 */
router.beforeEach((to, from, next) => {
  console.log('Router :', from, to)
  if (from?.name?.includes('customerinfo') && to?.name?.includes('planinfo')) {
    next({
      name: 'history',
    })
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['staff/loggedIn']) {
      console.log('You are not logged in, go to login page...')
      next({
        name: 'login',
      })
    } else {
      const userIsSellable =
        JSON.parse(localStorage.getItem('userInfo')).role !== 'NONLIFE_ASISTANT_BRANCH_MANAGER' ? true : false

      if (to.meta.isSellable && userIsSellable) {
        console.log('Go to next page...')
        next()
      } else if (!to.meta.isSellable) {
        next()
      } else {
        // For role NONLIFE_ASISTANT_BRANCH_MANAGER only
        console.log('Go to report page...')
        next({
          name: 'report',
        })
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route dont't requires auth, check if logged in
    if (store.getters['staff/loggedIn']) {
      console.log('You are logged in, go to product page...')
      next({
        name: 'product',
      })
    } else {
      console.log('Visitor Go to login page...')
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
