import axios from 'axios'

/**
 * initial state
 */
const state = () => ({
  business_type: localStorage.getItem('business_type') || '',
  building_type: localStorage.getItem('building_type') || '',
  business_details: localStorage.getItem('business_details') || '',
  mall_id: localStorage.getItem('mall_id') || '',
  mall_name: localStorage.getItem('mall_name') || '',
})

/**
 * getters
 */
const getters = {
  /**
   * Get business_type
   * @returns business_type
   */
  getBusinessType(state) {
    if (state.business_type) {
      return JSON.parse(state.business_type)
    } else return ''
  },

  /**
   * Get building_type
   * @returns build_type
   */
  getBuildingType(state) {
    return state.building_type
  },

  /**
   * Get business_details
   * @returns business_details
   */
  getBusinessDetails(state) {
    return state.business_details
  },

  /**
   * Get mall_id
   * @returns mall_id
   */
  getMallId(state) {
    return state.mall_id
  },

  /**
   * Get mall_name
   * @returns mall_name
   */
  getMallName(state) {
    return state.mall_name
  },
}

/**
 * mutations
 */
const mutations = {
  /**
   * Set business type
   * @param {*} state
   * @param {*} data
   */
  setBusinessType(state, data) {
    state.business_type = data
  },

  /**
   * Set building type
   * @param {*} state
   * @param {*} data
   */
  setBuildingType(state, data) {
    state.building_type = data
  },

  /**
   * Set business details
   * @param {*} state
   * @param {*} data
   */
  setBusinessDetails(state, data) {
    state.business_details = data
  },

  /**
   * Set mall information
   * @param { mall_id, mall_name} data
   */
  setMallInfo(state, data) {
    state.mall_id = data.mallId
    state.mall_name = data.mallName
  },

  /**
   * Set mallName information
   * @param { mall_name} data
   */
  setMallName(state, data) {
    state.mall_name = data.mallName
  },

  /**
   * Clear Building information
   */
  clearCurrentBuildingInfo(state) {
    state.business_type = ''
    state.building_type = ''
    state.business_details = ''
    state.mall_id = ''
    state.mall_name = ''
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Clear CurrentBuilding information
   */
  clearBuildingInfo(context) {
    console.log('clear BuildingInfo')
    context.commit('clearCurrentBuildingInfo')
    localStorage.removeItem('business_type')
    localStorage.removeItem('building_type')
    localStorage.removeItem('business_details')
    localStorage.removeItem('mall_id')
    localStorage.removeItem('mall_name')
    localStorage.removeItem('store_blacklist')
  },

  /**
   * Select business type
   * @param business_type
   */
  selectBusinessType(context, credentials) {
    console.log(credentials)
    localStorage.setItem('business_type', JSON.stringify(credentials.business_type)) // Set value to local storage
    context.commit('setBusinessType', JSON.stringify(credentials.business_type))
  },

  /**
   * Select business type
   * @param building_type
   */
  selectBuildingType(context, credentials) {
    localStorage.setItem('building_type', credentials.building_type) // Set value to local storage
    context.commit('setBuildingType', credentials.building_type)
  },

  /**
   * Input business details
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  inputBusinessDetails(context, credentials) {
    localStorage.setItem('business_details', credentials.businessDetails)
    context.commit('setBusinessDetails', credentials.businessDetails)
  },

  /**
   * Select mall
   * @param mall_id
   */
  selectMall(context, credentials) {
    localStorage.setItem('mall_id', credentials.mallId)
    localStorage.setItem('mall_name', credentials.mallName)
    context.commit('setMallInfo', credentials)
  },

  addMallOther(context, credentials) {
    localStorage.setItem('mall_name', credentials.mallName)
    context.commit('setMallName', credentials)
  },

  /**
   * Get business type list
   * @return List of business type
   */
  getBusinessTypeList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_BUSINESS_TYPE + '?search=all')
        .then((response) => {
          console.log('API Business_type', response)
          if (response.data.isSuccess) {
            // success
            resolve(response.data.data)
          } else {
            // !success
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Get building type list
   * @return List of build type
   */
  getBuildingTypeList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_BUILDING_TYPE)
        .then((response) => {
          console.log('API Building_type', response)
          if (response.data.isSuccess) {
            // success
            resolve(response.data.data)
          } else {
            // !success
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
