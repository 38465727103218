import axios from 'axios'

/**
 * initial state
 */
const state = () => ({
  business_type: localStorage.getItem('business_type') || '',
  building_type: localStorage.getItem('building_type') || '',
  business_details: localStorage.getItem('business_details') || '',
})

/**
 * getters
 */
const getters = {
  /**
   * Get business_type
   * @param {*} state
   * @returns
   */
  getBusinessType(state) {
    if (state.business_type) {
      return JSON.parse(state.business_type)
    } else return ''
  },

  /**
   * Get building_type
   * @param {*} state
   * @returns
   */
  getBuildingType(state) {
    return state.building_type
  },

  /**
   * Get business_details
   * @param {*} state
   * @returns
   */
  getBusinessDetails(state) {
    return state.business_details
  },
}

/**
 * mutations
 */
const mutations = {
  /**
   * Set business type
   * @param {*} state
   * @param {*} data
   */
  setBusinessType(state, data) {
    state.business_type = data
  },

  /**
   * Set building type
   * @param {*} state
   * @param {*} data
   */
  setBuildingType(state, data) {
    state.building_type = data
  },

  /**
   * Set business details
   * @param {*} state
   * @param {*} data
   */
  setBusinessDetails(state, data) {
    state.business_details = data
  },

  /**
   * Clear Building information
   */
  clearCurrentBuildingInfo(state) {
    state.business_type = ''
    state.building_type = ''
    state.business_details = ''
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Clear CurrentBuilding information
   */
  clearBuildingInfo(context) {
    console.log('clear BuildingInfo')
    context.commit('clearCurrentBuildingInfo')
    localStorage.removeItem('business_type')
    localStorage.removeItem('building_type')
    localStorage.removeItem('business_details')
  },

  /**
   * Select business type use for build info in ttb-no-deduct-ultra
   * @param business_type
   */
  selectBusinessType(context, credentials) {
    console.log(credentials)
    localStorage.setItem('business_type', JSON.stringify(credentials.business_type)) // Set value to local storage
    context.commit('setBusinessType', JSON.stringify(credentials.business_type))
  },

  /**
   * Select business type use for build info in ttb-no-deduct-ultra
   * @param building_type
   */
  selectBuildingType(context, credentials) {
    localStorage.setItem('building_type', credentials.building_type) // Set value to local storage
    context.commit('setBuildingType', credentials.building_type)
  },

  /**
   * Input business details
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  inputBusinessDetails(context, credentials) {
    localStorage.setItem('business_details', credentials.businessDetails)
    context.commit('setBusinessDetails', credentials.businessDetails)
  },

  /**
   * Get business type list
   * @param {*} credentials
   * @return List of business type
   */
  getBusinessTypeList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_360_BUSINESS_TYPE + '?search=all')
        .then((response) => {
          console.log('API Business_type', response)
          if (response.data.isSuccess) {
            // success
            resolve(response.data.data)
          } else {
            // !success
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Get building type list
   * @param {*} credentials
   * @return List of build type
   */
  getBuildingTypeList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_360_BUILDING_TYPE)
        .then((response) => {
          console.log('API Building_type', response)
          if (response.data.isSuccess) {
            // success
            resolve(response.data.data)
          } else {
            // !success
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
