<template>
  <div id="app" class="noselect">
    <!-- Navbar -->
    <Navbar />

    <!-- Contents views -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <!-- Modal alert already login -->
    <b-modal
      id="modal-already-login-alert-box"
      ref="modalAlreadyLoginAlertBox"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @show="autoRejectFromSystem"
    >
      <div class="position-relative" style="text-align: center; color: #032c63">
        <div class="position-absolute top-0 left-0 h2 mb-0">
          <b-icon icon="exclamation-circle" style="color: #f68c24" />
        </div>
        <div style="padding: 30px 0">
          <h3>การเข้าระบบซ้อนกัน</h3>
          <p style="margin-bottom: 36px">
            ขออภัย คุณไม่สามารถเข้าใช้โปรแกรมได้ <br />
            เนื่องจากการเข้าระบบซ้อนกัน
          </p>
          <br />
          <button
            class="ndbx-button emphasis medium"
            style="max-width: 240px; width: 100%"
            @click="rejectFromSystem"
            @touch="rejectFromSystem"
          >
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Modal session timeout -->
    <b-modal
      id="modal-session-timeout-alert-box"
      ref="modalSessionTimeoutAlertBox"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @show="autoRejectFromSystem"
    >
      <div class="position-relative" style="text-align: center; color: #032c63">
        <div class="position-absolute top-0 left-0 h2 mb-0">
          <b-icon icon="exclamation-circle" style="color: #f68c24" />
        </div>
        <div style="padding: 30px 0">
          <h3>เซสชันหมดอายุ</h3>
          <p style="margin-bottom: 36px">
            ขออภัย คุณไม่สามารถเข้าใช้โปรแกรมได้ <br />
            เซสชันของคุณหมดอายุ
          </p>
          <br />
          <button
            class="ndbx-button emphasis medium"
            style="max-width: 240px; width: 100%"
            @click="rejectFromSystem"
            @touch="rejectFromSystem"
          >
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Modal alert system error  -->
    <b-modal
      id="modal-system-error-alert-box"
      ref="modalSystemErrorAlertBox"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="position-relative" style="text-align: center; color: #032c63">
        <div class="position-absolute top-0 left-0 h2 mb-0">
          <b-icon icon="exclamation-circle" style="color: #f68c24" />
        </div>
        <div style="padding: 30px 0">
          <h3>ขออภัย เกิดข้อผิดพลาด</h3>
          <p style="margin-bottom: 36px">{{ systemErrorMessage }}</p>
          <button
            class="ndbx-button emphasis medium"
            style="max-width: 240px; width: 100%"
            @click="$bvModal.hide('modal-system-error-alert-box')"
            @touch="$bvModal.hide('modal-system-error-alert-box')"
          >
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Modal Flood error -->
    <b-modal
      id="modal-flood-alert"
      ref="modalFloodAlert"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="position-relative" style="text-align: center; color: #032c63">
        <div class="position-absolute top-0 left-0 h2 mb-0">
          <b-icon icon="exclamation-circle" style="color: #f68c24" />
        </div>
        <div style="padding: 30px 0">
          <h4>ขออภัย ขณะนี้ไม่สามารถดำเนินการ</h4>
          <p style="margin-bottom: 36px">ซื้อประกันภัยได้ในพื้นที่ที่คุณเลือก</p>

          <div class="my-4">
            <img
              src="@/static/images/not-found-page.png"
              alt="alert-image"
              style="margin: 1rem auto; max-width: 200px; width: 100%"
            />
          </div>

          <button
            class="ndbx-button emphasis medium"
            style="max-width: 180px; width: 100%"
            @click="$bvModal.hide('modal-flood-alert')"
            @touch="$bvModal.hide('modal-flood-alert')"
          >
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Modal Store error -->
    <b-modal
      id="modal-store-alert"
      ref="modalStoreAlert"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <div style="text-align: center; color: #032c63">
        <div style="padding: 30px 0">
          <h4 style="margin-bottom: 36px">
            ขออภัย ห้างสรรพสินค้าดังกล่าว บริษัทฯ
            <br />
            ไม่สามารถรับประกันภัยเพิ่มเติมได้
          </h4>

          <div class="my-4">
            <img
              src="@/static/images/not-found-page.png"
              alt="alert-image"
              style="margin: 1rem auto; max-width: 200px; width: 100%"
            />
          </div>

          <button
            class="ndbx-button emphasis medium"
            style="max-width: 180px; width: 100%"
            @click="$bvModal.hide('modal-store-alert')"
            @touch="$bvModal.hide('modal-store-alert')"
          >
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar,
  },
  data() {
    return {
      systemErrorMessage: '',
      isShow: false,
    }
  },
  computed: {
    showSessionTimeout() {
      return this.$store.getters['staff/getSessionTimeoutStatus']
    },
    showLoginOverlap() {
      return this.$store.getters['staff/getLoginOverlapStatus']
    },
  },
  watch: {
    showSessionTimeout(newVal) {
      if (newVal) {
        this.isShow = true
        this.$bvModal.show('modal-session-timeout-alert-box')
      }
    },
    showLoginOverlap(newVal) {
      if (newVal) {
        this.isShow = true
        this.$bvModal.show('modal-already-login-alert-box')
      }
    },
  },
  mounted() {
    this.$root.$on('showSystemErrorAlertPopup', this.showSystemErrorAlertPopup)
    this.$root.$on('showFloodAlert', this.showFloodAlertPopup)
    this.$root.$on('hideFloodAlert', this.hideFloodAlertPopup)
    this.$root.$on('showStoreAlert', this.showStoreAlertPopup)
    this.$root.$on('hideStoreAlert', this.hideStoreAlertPopup)
  },
  methods: {
    rejectFromSystem() {
      console.log('reject from system...')
      this.isShow = false
      this.$store.dispatch('staff/destroyAuth')
    },

    autoRejectFromSystem() {
      this.$bvModal.hide('modal-system-error-alert-box')
      console.log('Auto reject from system active...')
      setTimeout(() => {
        this.isShow = false
        this.$store.dispatch('staff/destroyAuth')
      }, 5000)
    },

    showSystemErrorAlertPopup(msg) {
      if (msg) this.systemErrorMessage = msg
      else this.systemErrorMessage = 'กรุณาลองใหม่อีกครั้ง'

      if (!this.isShow) {
        this.$bvModal.show('modal-system-error-alert-box')
      }
    },

    showFloodAlertPopup() {
      this.$bvModal.show('modal-flood-alert')
    },

    hideFloodAlertPopup() {
      this.$bvModal.hide('modal-flood-alert')
    },

    showStoreAlertPopup() {
      this.$bvModal.show('modal-store-alert')
    },

    hideStoreAlertPopup() {
      this.$bvModal.hide('modal-store-alert')
    },
  },
}
</script>

<style>
body,
html {
  height: 100%;
}

#app {
  font-family: 'NeueFrutigerThaiModern-Rg', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #032c63;
}

/* Arrow */
.collap-arrow {
  border: solid #006192;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: sup;
  transition: all 0.6s;
}

/* Arrow direction */
.collap-arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.collap-arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.collap-arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.collap-arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Close button */
.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 24px;
  height: 24px;
  opacity: 0.3;
  cursor: pointer;
}

.close-button:hover {
  opacity: 1;
}
.close-button:before,
.close-button:after {
  position: absolute;
  left: 12px;
  content: ' ';
  height: 24px;
  width: 2px;
  background-color: #333;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}

/* Transition animation */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

/* Cursor class*/
.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}

/* User for unselect text */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

/* Font style class */
.font-blod {
  font-family: 'NeueFrutigerThaiModern-Bd', Avenir, Helvetica, Arial, sans-serif;
}

/* Style for table on preview page */
.detail-preview-text-center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.detail-preview-text-middle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detail-preview-text-item {
  align-self: center;
}

/* custom tooltip */
.tooltip-inner {
  max-width: 300px !important;
  color: #032c63 !important;
  text-align: left !important;
  text-indent: -6px;
  background-color: #ffecd9 !important;
  padding: 0.75rem !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  font-family: 'NeueFrutigerThaiModern-Rg', Avenir, Helvetica, Arial, sans-serif !important;
}

.bs-tooltip-top .arrow::before {
  border-top-color: #ffecd9 !important;
}

#modal-already-login-alert-box,
#modal-session-timeout-alert-box,
#modal-system-error-alert-box,
#modal-flood-alert,
#modal-store-alert,
#modal-resend-quotation,
#modal-resend-quotation-successc,
#modal-renewal-send-quotation,
#modal-update-payment-status,
#modal-update-payment-status-success,
#modal-add-note,
#modal-add-note-status {
  font-family: 'NeueFrutigerThaiModern-Rg', Avenir, Helvetica, Arial, sans-serif;
  color: #224778;
}

/* Modal */
#modal-add-note .modal-header.bg-primary,
#modal-add-note-status .modal-header.bg-primary {
  background-color: #224778 !important;
}

#modal-add-note .modal-header button.close,
#modal-add-note-status .modal-header button.close {
  /* width: 20px;
  height: 20px;
  border-radius: 50%; */
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  color: #224778 !important;
  opacity: 1 !important;
  /* transform: translateY(50%); */
}

#modal-add-note .modal-header button.close:hover,
#modal-add-note-status .modal-header button.close:hover {
  opacity: 0.5 !important;
  /* color: white !important; */
}

#modal-add-note .modal-header button.close:active,
#modal-add-note-status .modal-header button.close:active {
  opacity: 1 !important;
}

#modal-add-note .modal-header .close,
#modal-add-note-status .modal-header .close {
  padding: 0 !important;
  padding-bottom: 4px !important;
  margin: 0 !important;
}

#modal-add-note textarea:disabled {
  color: rgba(21, 41, 67, 0.5) !important;
}

#modal-already-login-alert-box .modal-content,
#modal-session-timeout-alert-box .modal-content,
#modal-system-error-alert-box .modal-content,
#modal-flood-alert .modal-content,
#modal-store-alert .modal-content,
#modal-resend-quotation .modal-content,
#modal-resend-quotation-success .modal-content,
#modal-renewal-send-quotation .modal-content,
#modal-update-payment-status .modal-content,
#modal-update-payment-status-success .modal-content,
#modal-add-note .modal-content,
#modal-add-note-status .modal-content {
  border-radius: 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

/* Customer Tooltips */
.tooltip-inner {
  font-family: 'NeueFrutigerThaiModern-Rg', Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  color: #224778 !important;
  padding: 6px 12px !important;
  border-radius: 2px !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
  border: solid 1px #224778 !important;
  background-color: #fff !important;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #224778 !important;
}
.bs-tooltip-top .arrow::before {
  border-top-color: #224778 !important;
}
.bs-tooltip-left .arrow::before {
  border-left-color: #224778 !important;
}
.bs-tooltip-right .arrow::before {
  border-right-color: #224778 !important;
}

/* Responsive session */
/* Tablet size */
@media screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}

/* Desktop size */
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}
</style>
