import axios from 'axios'
import router from '../../router'

/**
 * initial state
 */
const state = () => ({
  // todo
  currentCustomerId: localStorage.getItem('customerId') || '',
  currentCompanyId: localStorage.getItem('companyId') || '',
})

/**
 * getters
 */
const getters = {
  // todo
  getCurrentInfo(state) {
    return {
      customerId: state.currentCustomerId,
      companyId: state.currentCompanyId,
    }
  },
}
/**
 * mutations
 */
const mutations = {
  // todo
  setCurrentInfo(state, data) {
    state.currentCustomerId = data.customerId
    state.currentCompanyId = data.companyId
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Set current information
   */
  setCurrentInfo(context, credentials) {
    console.log('set current info :', credentials)
    localStorage.setItem('customerId', credentials.customerId)
    localStorage.setItem('companyId', credentials.companyId)
    context.commit('setCurrentInfo', credentials)
  },

  /**
   * Creat Quotation
   */
  createQuotation(context, credentials) {
    console.log('params', credentials)
    return new Promise((resolve, reject) => {
      const params = {
        business_type: Number(credentials.businessTypeId),
        business_details: credentials.businessDetails,
        phone_customer: credentials.phoneCustomer,
        email_customer: credentials.emailCustomer,
        package_type: Number(credentials.packageType), // 1 = deduct, 2 = sme360, 4 = sme มีกันไว้
        buildingTypeId: Number(credentials.buildingTypeId),
        addressId: Number(credentials.addressId),
        building_insurance: Number(credentials.buildingInsurance),
        furniture_insurance: Number(credentials.furnitureInsurance),
        stock_insurance: Number(credentials.stockInsurance),
        stock_details: credentials.stockDetails,
        machine_insurance: Number(credentials.machineInsurance),
        machine_details: credentials.machineDetails,
        other_insurance: Number(credentials.otherInsurance),
        details: credentials.otherDetails,
        remark: credentials.remark,
        suminsurance_total: Number(credentials.sumInsuranceTotal),
        checkbox_building: credentials.checkboxBuilding,
        plan_id: Number(credentials.planId),
        protection_start_date: credentials.protectionStartDate,
        insured_elsewhere: credentials.insuranceElseWhere,
        insurance_companyname_id: Number(credentials.insuranceCompanyNameId),
        insurance_companyname_name: credentials.insuranceCompanyNameName,
        premium: credentials.premium,
        area: credentials.area,
        width: credentials.width,
        length: credentials.length,
        floors: credentials.floors,
      }

      // Add mall lists if value is existing
      if (credentials.mallId && credentials.mallName) {
        params.mallId = credentials.mallId
        params.mallName = credentials.mallName
      }

      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_CREATE, params)
        .then((response) => {
          console.log('API create quotaion ::', response)
          if (response.data.isSuccess) {
            resolve(response)
          } else {
            if (
              response.data.code === 1001 &&
              response.data.message === 'protection_start_date not before current date'
            ) {
              resolve(response)
            } else reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * List Quotation
   */
  listQuotation(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API list quotation params ::', credentials)
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_LIST_VIEW, credentials)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * View Quotation
   * @param smeFireInsuranceId
   * @return {array}
   */
  viewQuotation(context, credentials) {
    // console.log("params ::", credentials)
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_DETAIL + '/' + credentials.smeFireInsuranceId)
        .then((response) => {
          console.log('API view quotaion ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  isEmptyObject(obj) {
    return Object.keys(obj).length === 0
  },

  /**
   * View Quotation Detail
   * @param smeFireInsuranceId
   * @return {array}
   */
  viewQuotationDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_VIEW + '/' + credentials.smeFireInsuranceId)
        .then((response) => {
          console.log('API view quotation detail ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              if (
                Object.keys(response.data.data.premiumDetail).length === 0 &&
                response.data.data.customerDetail == null
              ) {
                resolve('No content')
                console.error('No content')
                router.push({ name: 'history' })
              }
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          } else reject(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * View customer detail
   * @param smeFireInsuranceId
   * @return {array}
   */
  viewCustomerDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_CUSTOMER_DETAIL + '/' + credentials.smeFireInsuranceId)
        .then((response) => {
          console.log('API view customer detail ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Create Assured
   */
  createAssured(context, credentials) {
    console.log('createAssured params ::', credentials)

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_CREATE_ASSURED, {
          isSendCustomer: credentials.isSendCustomer,
          smeFireinsuranceId: credentials.smeFireinsuranceId,
          customerId: credentials.customerId,
          companyId: credentials.companyId,
          signature: credentials.signature,
          privacy_1: credentials.privacy1,
          privacy_2: credentials.privacy2,
          privacy_3: credentials.privacy3,
          financial_institution_id: credentials.financialInstitutionId,
          financial_institution_name: credentials.financialInstitutionName,
          obligations: credentials.obligations,
          other_details: credentials.otherDetails,
          assured_type: credentials.assuredType, // 1 == normal, 2 == legal
          firstname: credentials.firstname,
          lastname: credentials.lastname,
          phone: credentials.phone,
          email: credentials.email,
          id_card: credentials.idCard,
          date_of_birth: credentials.dateOfBirth,
          establishment_name: credentials.establishmentName,
          juristic_number: credentials.juristicNumber,
          taxpayer_ID: credentials.taxpayerId,
          branch_office: credentials.branchOffice, // 1 == headquarter, 2 == branch
          branch_name: credentials.branchName,
          branch_number: credentials.branchNumber,
          position: credentials.position,
          firstname_contact: credentials.firstNameContact,
          lastname_contact: credentials.lastNameContact,
          phone_contact: credentials.phoneContact,
          email_contact: credentials.emailContact,
          withholding_tax: credentials.withHoldingTax,
          prefix: credentials.prefix,
          otp_consent: credentials.otpConsent,
          serviceChannelId: credentials.serviceChannelId,
          cus_no: 'A1001',
          rm_id: credentials.rmId || '',
        })
        .then((response) => {
          console.log('API create assured ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * View public Quotation
   * @param smeFireInsuranceId
   * @return {array}
   */
  viewPublicQuotation(context, credentials) {
    // console.log("params ::", credentials)
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_PUBLIC_DETAIL + '/' + credentials.smeFireInsuranceId)
        .then((response) => {
          console.log('API view public quotaion ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * View public customer detail
   * @param smeFireInsuranceId
   * @return {array}
   */
  viewPublicCustomerDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_PUBLIC_CUSTOMER_DETAIL + '/' + credentials.smeFireInsuranceId)
        .then((response) => {
          console.log('API view public customer detail ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Create Public assured
   */
  createPublicAssured(context, credentials) {
    console.log('createAssured params ::', credentials)

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_PUBLIC_CREATE_ASSURED, {
          smeFireinsuranceId: credentials.smeFireinsuranceId,
          customerId: credentials.customerId,
          companyId: credentials.companyId,
          signature: credentials.signature,
          privacy_1: credentials.privacy1,
          privacy_2: credentials.privacy2,
          privacy_3: credentials.privacy3,
          financial_institution_id: credentials.financialInstitutionId,
          financial_institution_name: credentials.financialInstitutionName,
          obligations: credentials.obligations,
          other_details: credentials.otherDetails,
          assured_type: credentials.assuredType, // 1 == normal, 2 == legal
          firstname: credentials.firstname,
          lastname: credentials.lastname,
          phone: credentials.phone,
          email: credentials.email,
          id_card: credentials.idCard,
          date_of_birth: credentials.dateOfBirth,
          establishment_name: credentials.establishmentName,
          juristic_number: credentials.juristicNumber,
          taxpayer_ID: credentials.taxpayerId,
          branch_office: credentials.branchOffice, // 1 == headquarter, 2 == branch
          branch_name: credentials.branchName,
          branch_number: credentials.branchNumber,
          position: credentials.position,
          firstname_contact: credentials.firstNameContact,
          lastname_contact: credentials.lastNameContact,
          phone_contact: credentials.phoneContact,
          email_contact: credentials.emailContact,
          withholding_tax: credentials.withHoldingTax,
          prefix: credentials.prefix,
        })
        .then((response) => {
          console.log('API create assured ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Create address
   */
  createAddress(context, credentials) {
    console.log('create Address params ::', credentials)

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_CREATE_ADDRESS, {
          smeFireinsuranceId: credentials.smeFireInsuranceId,
          send_postage: credentials.sendPostage,
          customerId: credentials.customerId,
          companyId: credentials.companyId,
          address: credentials.addressItems,
        })
        .then((response) => {
          console.log('API create address ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**
   * Update address
   */
  updateAddress(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .put(process.env.VUE_APP_SME_FIRE_INSURANCE_UPDATE_ADDRESS + '/' + credentials.addressId, {
          smeFireinsuranceId: Number(credentials.smeFireInsuranceId),
          addressTypeId: Number(credentials.addressType),
          group_number: credentials.groupNumber,
          title_deed_number: credentials.deedNumber || null,
          building: credentials.building || null,
          room_number: credentials.roomNumber || null,
          village_no: credentials.villageNo || null,
          alley: credentials.alley || null,
          road: credentials.road || null,
          province: Number(credentials.province),
          district: Number(credentials.district),
          sub_district: Number(credentials.subDistrict),
          province_name: credentials.provinceName,
          district_name: credentials.districtName,
          subdistrict_name: credentials.subDistrictName,
          postcode: credentials.postcode,
          country: credentials.country,
        })
        .then((response) => {
          console.log('API update address ::', response.data)
          if (response.data.isSuccess) {
            resolve(response.data.data)
          } else {
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Staff
   * Get payment status
   */
  getPaymentStatus(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API getPaymentStatus ::', context, credentials)
      const params = credentials.insurance_no

      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_CHECK_PAYMENT_STATUS + '/' + params)
        .then((res) => resolve(res))
        .catch((error) => reject(error))
    })
  },

  /**
   * Public
   * Get payment status
   */
  getPublicPaymentStatus(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API getPublicPaymentStatus ::', context, credentials)
      const params = credentials.insurance_no

      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_PUBLIC_CHECK_PAYMENT_STATUS + '/' + params)
        .then((res) => resolve(res))
        .catch((error) => reject(error))
    })
  },

  /**
   * Update payment status
   */
  updatePaymentStatus(context, params) {
    return new Promise((resolve, reject) => {
      console.log('API updatePaymentStatus ::', context, params)
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_UPDATE_PAYMENT_STATUS, params)
        .then((res) => resolve(res))
        .catch((error) => reject(error))
    })
  },

  /**
   * Get new quotation
   */
  async genQuotation(context, credentials) {
    try {
      const res = await axios.get(
        process.env.VUE_APP_SME_FIRE_INSURANCE_GEN_QUOTATION + '/' + credentials.smeFireInsuranceId
      )
      console.log(res)
      if (res.status === 200) {
        return true
      }
    } catch (err) {
      console.error(err)
      return false
    }
  },

  /**
   * Daily report
   */
  async sendReport(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_SME_FIRE_INSURANCE_DAILY_REPORT, credentials)
      console.log('Response api daily report', res)

      if (res.status === 200 || res.status === 204) return res
    } catch (err) {
      console.error(err)
      return false
    }
  },

  async sendQuotation(context, credentials) {
    try {
      const res = await axios.post(
        process.env.VUE_APP_SME_FIRE_INSURANCE_SEND_QUOTATION + '/' + credentials.smeFireInsuranceId,
        {
          email: credentials.email,
          phone: credentials.phone,
        }
      )

      if (res.status === 200 || res.status === 204) return res
    } catch (err) {
      console.error(err)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
