<template>
  <div id="navbar-custom">
    <b-navbar>
      <b-button v-if="isLogin" v-b-toggle.side-menu variant="transparent">
        <div class="hamberger" />
        <div class="hamberger" />
        <div class="hamberger" />
      </b-button>
      <b-navbar-brand>
        <img class="pointer" src="@/static/images/logo-default.png" alt="logo" @click="goToHomePage" />
      </b-navbar-brand>
      <p class="navbar-text-custom">
        รับประกันวินาศภัยโดย บริษัท อลิอันซ์ อยุธยา ประกันภัย จํากัด (มหาชน) <br />
        ธนาคารทหารไทยธนชาต จำกัด (มหาชน) เป็นเพียงนายหน้าประกันวินาศภัย และรับผิดชอบในฐานะนายหน้าเท่านั้น
      </p>

      <b-navbar-nav v-if="isLogin && staffInfo" class="ml-auto">
        <p class="mb-0 text-left" style="font-size: 14px">
          {{ staffInfo?.prefix || 'ไม่ระบุ' }} {{ staffInfo?.name || 'ไม่ระบุ' }} {{ staffInfo?.surname || 'ไม่ระบุ' }}
          <br />
          เลขที่ใบอนุญาต {{ staffInfo?.license || 'ไม่ระบุ' }}
        </p>
      </b-navbar-nav>
    </b-navbar>

    <b-sidebar id="side-menu" title="Sidebar" no-header shadow backdrop @change="toggleBodyScrollbar">
      <div>
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item v-if="isCanSell" href="/history"> ประวัติใบคำขอ </b-nav-item>
            <b-nav-item v-if="isCanSell" href="/product"> เลือกแผนประกันภัย </b-nav-item>
            <b-nav-item v-if="isCanSell" href="/renew/history"> ต่ออายุกรรมธรรม์ </b-nav-item>
            <b-nav-item href="/report"> ออกรายงาน </b-nav-item>
            <b-nav-item @click="logout"> ออกจากระบบ </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'NavbarCustom',
  computed: {
    isLogin() {
      return this.$store.getters['staff/loggedIn']
    },
    staffInfo() {
      return this.$store.getters['staff/getUserInformation']
    },
    isCanSell() {
      if (this.$store.getters['staff/getUserInformation']?.role === 'NONLIFE_ASISTANT_BRANCH_MANAGER') return false
      else return true
    },
  },
  methods: {
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName('body')[0]

      if (visible) body.classList.add('overflow-hidden')
      else body.classList.remove('overflow-hidden')
    },

    async logout() {
      this.$store.dispatch('staff/destroyAuth')
    },

    goToHomePage() {
      window.location.href = '/'
    },
  },
}
</script>

<style>
#navbar-custom .navbar {
  padding: 0.5rem 1rem;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

#navbar-custom .navbar-brand {
  margin-left: 16px;
}

#navbar-custom .btn {
  padding: 3px 6px !important;
}

#navbar-custom .b-sidebar {
  top: 60px !important;
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar li {
  padding: 0.75rem !important;
  text-align: left !important;
  border: 1px solid #405f88 !important;
  background-color: #032c63 !important;
}

#navbar-custom .b-sidebar li:hover {
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar li a {
  color: #fff !important;
}

#navbar-custom img {
  padding: 1rem 0;
  height: 3.75rem;
  max-height: 100%;
}

#navbar-custom div.hamberger {
  width: 26px;
  height: 3px;
  margin: 6px 0;
  background-color: #032d64;
}

.navbar-text-custom {
  max-width: 580px;
  width: 100%;
  color: #032c63;
  font-size: 12px;
  text-align: left;
  margin-bottom: 0;
}

@media screen and (max-width: 699px) {
  .navbar-text-custom {
    display: none;
  }
}
</style>
