import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSignaturePad from 'vue-signature-pad'
import VueLoading from 'vue-loading-overlay'
import VCalendar from '@komdech/v-calendar'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import VueLoading CSS
import 'vue-loading-overlay/dist/vue-loading.css'

// Import font
import './assets/font/stylesheet.css'

// Import ndbx button style
import './assets/ndbx/dropdownfilter.css'
import './assets/ndbx/select.css'
import './assets/ndbx/input.css'
import './assets/ndbx/radio.css'
import './assets/ndbx/button.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// User vue loading
Vue.use(VueLoading)
// Use vue signatuer pad
Vue.use(VueSignaturePad)
// Use VCalendar
Vue.use(VCalendar)

// Thati bath filter
Vue.filter('thaiBath', function (value) {
  if (value) {
    const number = Number.parseFloat(value)
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const result = number % 1 !== 0 ? number.toLocaleString('th-TH', options) : number.toLocaleString('th-TH')

    return result
  } else return 0
})

// Thai date filter
Vue.filter('thaiDate', function (value) {
  var d = new Date(value),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear() + 543

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('/')
})

// yyyy-mm-dd date filter
Vue.filter('date', function (value) {
  var d = new Date(value),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  // Check format for
  return [year, month, day].join('-')
})

// Validate Phone
Vue.filter('validatePhone', function (phone) {
  const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4}$/im

  return re.test(phone)
})

// Validate Email
Vue.filter('validateEmail', function (email) {
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
})

// Validate laserId
Vue.filter('validateLaserId', function (laserId) {
  if (laserId.length !== 12) return false
  if (laserId) {
    const re = /^([A-Z]{2})+([0-9]{10})$/
    return re.test(laserId)
  }
})

// Validate citizenId
Vue.filter('validateCitizenId', function (citizenId) {
  if (citizenId.length !== 13) return false
  let sum = 0
  for (let i = 0; i < 12; i++) {
    sum += parseInt(citizenId.charAt(i)) * (13 - i)
  }
  const mod = sum % 11
  const check = (11 - mod) % 10
  if (check === parseInt(citizenId.charAt(12))) {
    return true
  }
  return false
})

Vue.config.productionTip = false

/**
 * Axios request interceptors
 */
axios.interceptors.request.use(async (config) => {
  const jwtToken = await store.getters['staff/getAccessToken']
  if (jwtToken) {
    config.headers.Authorization = 'Bearer ' + jwtToken
  }

  return config
})

/**
 * Axios response interceptors
 */

// Uncomment if using refresh token
// let isRefreshing = false
// let subscribers = []
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (err) => {
    const {
      // config,
      response: { status, data },
    } = err

    // const originalRequest = config

    // Check Bad request
    if (status === 400) return Promise.reject(err)

    // Check refresh token expired
    if (status === 401 && data.code == 1402) {
      console.log('session timeout..')
      store.dispatch('staff/showSessionTimeout')

      return Promise.reject(false)
    }

    // Check login overlap
    if (status === 401 && data.code == 1404) {
      console.log('login overlap ...')
      store.dispatch('staff/showLoginOverlap')

      return Promise.reject(false)
    }

    // Check access token expired
    if (status === 401 && data.code == 1401) {
      console.log('session timeout..')
      store.dispatch('staff/showSessionTimeout')

      return Promise.reject(false)
    }

    if (
      status === 401 &&
      (data.code == 1403 || data.code == 4011 || data.code == 4013 || data.code == 4014 || data.code == 2000)
    ) {
      return Promise.reject(err)
    }
  }
)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
