import axios from 'axios'

/**
 * initial state
 */
const state = () => ({
  auth_info: {
    client_id: localStorage.getItem('client_id') || '',
    staff_id: localStorage.getItem('staff_id') || '',
    access_token: localStorage.getItem('access_token') || '',
    expires_in: localStorage.getItem('expires_in') || '',
    expiration: localStorage.getItem('expiration') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
    refresh_expires_in: localStorage.getItem('refresh_expires_in') || '',
    refresh_expiration: localStorage.getItem('refresh_expiration') || '',
  },
  shadow_info: {
    shadow_type: localStorage.getItem('shadowInfomation')?.type || null,
    shadow_id: localStorage.getItem('shadowInfomation')?.id || null,
    shadow_name: localStorage.getItem('shadowInfomation')?.name || null,
    shadow_surname: localStorage.getItem('shadowInfomation')?.surname || null,
    shadow_branch_code_refer: localStorage.getItem('shadowInfomation')?.branchCode || null,
    shadow_branch_name_refer: localStorage.getItem('shadowInfomation')?.branchName || null,
    shadow_district_code_refer: localStorage.getItem('shadowInfomation')?.districtCode || null,
    shadow_district_name_refer: localStorage.getItem('shadowInfomation')?.districtName || null,
    shadow_zone_code_refer: localStorage.getItem('shadowInfomation')?.zoneCode || null,
    shadow_zone_name_refer: localStorage.getItem('shadowInfomation')?.zoneName || null,
    shadow_region_code_refer: localStorage.getItem('shadowInfomation')?.regionCode || null,
    shadow_region_name_refer: localStorage.getItem('shadowInfomation')?.regionName || null,
    shadow_title_refer: localStorage.getItem('shadowInfomation')?.title || null,
  },
  user_info: {
    prefix: JSON.parse(localStorage.getItem('userInfo'))?.prefix || null,
    name: JSON.parse(localStorage.getItem('userInfo'))?.name || null,
    surname: JSON.parse(localStorage.getItem('userInfo'))?.surname || null,
    license: JSON.parse(localStorage.getItem('userInfo'))?.license || null,
    role: JSON.parse(localStorage.getItem('userInfo'))?.role || null,
  },
  sessionTimeout: false,
  loginOverlap: false,
  storeBlacklist: JSON.parse(localStorage.getItem('storeBlacklist')) || null,
})

/**
 * getters
 */
const getters = {
  /**
   * User for Navigation guard
   */
  loggedIn(state) {
    return state.auth_info.access_token !== ''
  },

  /**
   * Get access token
   * @param {*} state
   * @returns
   */
  getAccessToken(state) {
    return state.auth_info.access_token
  },

  /**
   * Get refresh token
   * @param {*} state
   * @returns
   */
  getRefreshToken(state) {
    return state.auth_info.refresh_token
  },

  /**
   * Get auth info
   * @param {*} state
   * @returns state
   */
  authInfo(state) {
    return state.auth_info
  },

  getShadowInformation(state) {
    return state.shadow_info
  },

  getSessionTimeoutStatus(state) {
    return state.sessionTimeout
  },

  getLoginOverlapStatus(state) {
    return state.loginOverlap
  },

  getUserInformation(state) {
    return state.user_info
  },

  getStoreBlacklist(state) {
    return state.storeBlacklist
  },
}

/**
 * mutations
 */
const mutations = {
  /**
   * Set user_info
   * @param {*} state
   * @param {*} data
   */
  setAuthInfo(state, data) {
    state.auth_info.client_id = data.clientId
    state.auth_info.staff_id = data.staffId
    state.auth_info.access_token = data.accessToken
    state.auth_info.expires_in = data.expiresIn
    state.auth_info.expiration = data.expiration
    state.auth_info.refresh_token = data.refreshToken
    state.auth_info.refresh_expires_in = data.refreshExpiresIn
    state.auth_info.refresh_expiration = data.refreshExpiration
  },

  /**
   * DetroyAuth state info
   * @param {*} state
   */
  destroyAuth(state) {
    state.auth_info.client_id = ''
    state.auth_info.staff_id = ''
    state.auth_info.access_token = ''
    state.auth_info.expires_in = ''
    state.auth_info.expiration = ''
    state.auth_info.refresh_token = ''
    state.auth_info.refresh_expires_in = ''
    state.auth_info.refresh_expiration = ''
  },

  /**
   * Set shadow information
   */
  setShadowInformation(state, data) {
    state.shadow_info.shadow_type = data.type
    state.shadow_info.shadow_id = data.shadowId || null
    state.shadow_info.shadow_name = data.shadowName || null
    state.shadow_info.shadow_surname = data.shadowSurname || null
    state.shadow_info.shadow_branch_code_refer = data.shadowBranchCodeRefer || null
    state.shadow_info.shadow_branch_name_refer = data.shadowBranchNameRefer || null
    state.shadow_info.shadow_district_code_refer = data.shadowDistrictCodeRefer || null
    state.shadow_info.shadow_district_name_refer = data.shadowDistrictNameRefer || null
    state.shadow_info.shadow_zone_code_refer = null
    state.shadow_info.shadow_zone_name_refer = null
    state.shadow_info.shadow_region_code_refer = data.shadowRegionCodeRefer || null
    state.shadow_info.shadow_region_name_refer = data.shadowRegionNameRefer || null
    state.shadow_info.shadow_title_refer = data.shadowTitleRefer || null
  },

  /**
   * Clear shadow information
   */
  clearShadowInformation(state) {
    state.shadow_info.shadow_type = null
    state.shadow_info.shadow_id = null
    state.shadow_info.shadow_name = null
    state.shadow_info.shadow_surname = null
    state.shadow_info.shadow_branch_code_refer = null
    state.shadow_info.shadow_branch_name_refer = null
    state.shadow_info.shadow_district_code_refer = null
    state.shadow_info.shadow_district_name_refer = null
    state.shadow_info.shadow_zone_code_refer = null
    state.shadow_info.shadow_zone_name_refer = null
    state.shadow_info.shadow_region_code_refer = null
    state.shadow_info.shadow_region_name_refer = null
    state.shadow_info.shadow_title_refer = null
  },

  setSessionTimeout(state, data) {
    state.sessionTimeout = data
  },

  setLoginOverlap(state, data) {
    state.loginOverlap = data
  },

  setUserInformation(state, data) {
    state.user_info.prefix = data.prefix
    state.user_info.name = data.name
    state.user_info.surname = data.surname
    state.user_info.license = data.license
    state.user_info.role = data.role
  },

  setBlacklist(state, data) {
    state.storeBlacklist = data
  },
}

/**
 * actions
 */
const actions = {
  showSessionTimeout(context) {
    // localStorage.setItem('sesionTimeout', true)
    context.commit('setSessionTimeout', true)
  },

  showLoginOverlap(context) {
    // localStorage.setItem('loginOverlap', true)
    context.commit('setLoginOverlap', true)
  },

  /**
   * Login Function
   * @param {*} context
   * @param {*} credentials
   */
  async retrieveAuth(context, credentials) {
    console.log('API login', credentials)
    try {
      const res = await axios.post(process.env.VUE_APP_LOGIN, credentials)
      console.log('Response API login', res)

      if (res.status === 200 && res.data.isSuccess) {
        const authInfo = res.data.data

        // Set to local storage
        localStorage.setItem('client_id', authInfo.clientId)
        localStorage.setItem('staff_id', authInfo.staffId)
        localStorage.setItem('access_token', authInfo.accessToken)
        localStorage.setItem('expires_in', authInfo.expiresIn)
        localStorage.setItem('expiration', authInfo.expiration)
        localStorage.setItem('refresh_token', authInfo.refreshToken)
        localStorage.setItem('refresh_expires_in', authInfo.refreshExpiresIn)
        localStorage.setItem('refresh_expiration', authInfo.refreshExpiration)

        // Set to state
        context.commit('setAuthInfo', authInfo)
        // Set user info
        await context
          .dispatch('getUserInformation')
          .then((res) => {
            console.log('Get userInformation from retrieve auth :>> ', res)
            const data = {
              prefix: res.staff_title_name,
              name: res.staffName,
              surname: res.staffSurname,
              license: res.license,
              role: res.role,
            }
            localStorage.setItem('userInfo', JSON.stringify(data))
            context.commit('setUserInformation', data)
            return
          })
          .catch((error) => {
            console.error(error)
            return
          })

        return res
      } else {
        console.error(res.data.message)
        if (res.data.code === 1010 && res.data.message === 'Username and Password and license No information found') {
          return res
        } else return false
      }
    } catch (err) {
      console.error(err.response.data.message)
      return err.response.data
    }
  },

  /**
   * Refresh access token function
   * @param {*} context
   */
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      const params = {
        refreshToken: context.getters.getRefreshToken,
        grantType: 'refresh_token',
      }

      axios
        .post(process.env.VUE_APP_LOGIN, params)
        .then(function (response) {
          if (response.data.isSuccess) {
            const authInfo = response.data.data

            // Set to local storage
            localStorage.setItem('client_id', authInfo.clientId)
            localStorage.setItem('staff_id', authInfo.staffId)
            localStorage.setItem('access_token', authInfo.accessToken)
            localStorage.setItem('expires_in', authInfo.expiresIn)
            localStorage.setItem('expiration', authInfo.expiration)
            localStorage.setItem('refresh_token', authInfo.refreshToken)
            localStorage.setItem('refresh_expires_in', authInfo.refreshExpiresIn)
            localStorage.setItem('refresh_expiration', authInfo.refreshExpiration)

            // Set to state
            context.commit('setAuthInfo', authInfo)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  /**
   * Logout Function :: Clear state.auth, localStorage
   * @param {*} context
   */
  async destroyAuth(context) {
    if (context.getters.loggedIn) {
      const res = await axios.post(process.env.VUE_APP_REVOKE)
      const url = await window.location.origin
      const link = `https://services.tmbbank.com/adfs/ls/?wa=wsignout1.0&wreply=${url}`

      if (res.data.isSuccess) {
        context.commit('destroyAuth')
        localStorage.clear()
        window.location.replace(link)
      } else {
        console.log(res)
        location.reload()
      }
    }
  },

  /**
   * Get address list
   * @param provId string
   * @param bordId string
   * @return {Array}
   */
  getAddressList(context, credentials) {
    let params = credentials.param || ''

    console.log('Get Address Param', params)

    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ADDRESS_LIST + '?' + params)
        .then((response) => {
          console.log('API Address List ::', response)
          if (response.data.isSuccess) {
            // isSuccess
            resolve(response.data.data)
          } else {
            // !isSuccess
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * Get company list
   * @return {Array}
   */
  getCompanyList(context, credentials) {
    let params = credentials.param || ''

    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_INSURANCE_COMPANY_LIST + '?' + params)
        .then((response) => {
          console.log('API Insurance company list ::', response)
          if (response.data.isSuccess) {
            // isSuccess
            resolve(response.data.data)
          } else {
            // !isSuccess
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Get bank list
   * @param {*} context
   * @returns
   */
  getBankList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_BANK_LIST)
        .then((response) => {
          console.log('API Bank list ::', response)
          if (response.data.isSuccess) {
            // isSuccess
            resolve(response.data.data)
          } else {
            // !isSuccess
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Function get user from ref staff_id
   * @param id string
   * @return {Array}
   */
  getUserList(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_USER_LIST + `?search=${credentials?.filter || ''}`)
        .then((response) => {
          console.log('API user list ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              resolve(response.data.data)
            } else reject(response)
          } else if (response.status == 204) {
            console.log('Result is empty')
            resolve([])
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Get user information
   */
  async getUserInformation(context) {
    try {
      if (!context.getters.authInfo.staff_id) return
      const res = await axios.get(process.env.VUE_APP_USER_ME + '/' + context.getters.authInfo.staff_id)
      console.log('API user info ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
      return false
    }
  },

  /**
   * Get and Set select shadow informarion
   * @param {type, id, name, surname}
   */
  async getShadownSelected(context, credentials) {
    if (credentials.type === 2) {
      const params = {
        type: credentials.type,
        id: null,
        name: null,
        surname: null,
        branchCode: null,
        branchName: null,
        districtCode: null,
        districtName: null,
        zoneCode: null,
        zoneName: null,
        regionCode: null,
        regionName: null,
        title: null,
      }
      localStorage.setItem('shadowInfomation', JSON.stringify(params))
      context.commit('setShadowInformation', credentials)
    } else if (credentials.type === 3) {
      console.log('get', credentials)
      const params = {
        type: credentials.type,
        id: credentials.shadowId,
        name: credentials.shadowName,
        surname: credentials.shadowSurnames,
        branchCode: credentials.shadowBranchCodeRefer,
        branchName: credentials.shadowBranchNameRefer,
        districtCode: credentials.shadowDistrictCodeRefer,
        districtName: credentials.shadowDistrictNameRefer,
        zoneCode: null,
        zoneName: null,
        regionCode: credentials.shadowRegionCodeRefer,
        regionName: credentials.shadowRegionNameRefer,
        title: credentials.shadowTitleRefer,
      }
      localStorage.setItem('shadowInfomation', JSON.stringify(params))
      context.commit('setShadowInformation', credentials)
    }
  },

  /**
   * Update Shadow staff information
   * @param { smeFireInsuranceId, shadowId, shadowName, shadowSurname}
   */
  async updateShadownInformation(context, credentials) {
    try {
      console.log(credentials)
      console.log(context.getters.getShadowInformation)
      const params = {
        smeFireinsuranceId: credentials.smeFireinsuranceId,
        shadowType: context.getters.getShadowInformation.shadow_type,
        shadowId: context.getters.getShadowInformation.shadow_id,
        shadowName: context.getters.getShadowInformation.shadow_name,
        shadowSurname: context.getters.getShadowInformation.shadow_surname,
        branchCode_refer: context.getters.getShadowInformation.shadow_branch_code_refer,
        branchName_refer: context.getters.getShadowInformation.shadow_branch_name_refer,
        districtCode_refer: context.getters.getShadowInformation.shadow_district_code_refer,
        districtName_refer: context.getters.getShadowInformation.shadow_district_name_refer,
        zoneCode_refer: null,
        zoneName_refer: null,
        regionCode_refer: context.getters.getShadowInformation.shadow_region_code_refer,
        regionName_refer: context.getters.getShadowInformation.shadow_region_name_refer,
        shadowTitle: context.getters.getShadowInformation.shadow_title_refer,
      }
      console.log(params)
      const res = await axios.post(process.env.VUE_APP_SME_FIRE_INSURANCE_UPDATE_SHADOW, params)
      console.log('API update shadow information ::', res)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },

  clearShadow(context) {
    context.commit('clearShadowInformation')
    localStorage.removeItem('shadowInfomation')
  },

  async getCustomerPrefix() {
    try {
      const res = await axios.get(process.env.VUE_APP_CUSTOMER_PREFIX)
      console.log('API Get customer prefix ::', res)
      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      }
    } catch (err) {
      console.error(err)
      return []
    }
  },

  async checkFloodList(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_DISASTER_CHECK_LISTS, credentials)
      console.log(res)

      if (res.status === 200) {
        if (res.data.isSuccess) {
          return true
        } else return false
      } else return false
    } catch (err) {
      console.error(err)
      return false
    }
  },

  async checkFloodFeatureStatus() {
    try {
      const res = await axios.get(process.env.VUE_APP_DISASTER_STATUS)
      console.log(res)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data.data
      }
    } catch (err) {
      console.error(err)
      return false
    }
  },

  /**
   * Check personal risk for create new user
   */
  async checkCreateNewUserPersonalRisk(context, credentials) {
    try {
      const res = await axios.post(process.env.VUE_APP_CREATE_NEW_USER_CHECK_PERSONAL_RISK, credentials)
      console.log(res)

      if (res.status === 200) {
        return res
      }
    } catch (err) {
      console.error(err)
      return false
    }
  },

  async getStoreLists(context, credentials) {
    try {
      const res = await axios.get(process.env.VUE_APP_GET_STORE_LISTS, credentials)
      console.log('API Get store lists ::', res)
      if (res.status === 200 && res.data.isSuccess) {
        const lists = await res.data.data
        const Lists = await lists.filter((x) => x.active === true)
        const blackLists = await lists.filter((x) => x.active === false)

        context.commit('setBlacklist', blackLists)
        localStorage.setItem('store_blacklist', JSON.stringify(blackLists))

        return Lists
      }
    } catch (err) {
      console.error(err)
      return []
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
