import axios from 'axios'

/**
 * initial state
 */
const state = () => ({
  // todo
  currentBuildingInfo: localStorage.getItem('currentBuildingInfo') || '',
})

/**
 * getters
 */
const getters = {
  // todo
  getCurrentBuildingInfo(state) {
    if (state.currentBuildingInfo) {
      return JSON.parse(state.currentBuildingInfo)
    } else return ''
  },
}

/**
 * mutations
 */
const mutations = {
  // todo

  /**
   * Set currentBuildingInfo
   * @param {*} state
   * @param {*} data
   */
  setCurrentBuildingInfo(state, data) {
    state.currentBuildingInfo = data
  },

  /**
   * Clear CurrentBuilding information
   */
  clearCurrentBuildingInfo(state) {
    state.currentBuildingInfo = ''
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Clear CurrentBuilding information
   */
  clearCurrentBuildingInfo(context) {
    console.log('clear currentBuildingInfo')
    context.commit('clearCurrentBuildingInfo')
    localStorage.removeItem('currentBuildingInfo')
  },

  /**
   * Creat address
   */
  createAddress(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_ADDRESS_CREATE, {
          addressTypeId: Number(credentials.addressType),
          group_number: credentials.groupNumber,
          title_deed_number: credentials.deedNumber,
          building: credentials.building,
          room_number: credentials.roomNumber,
          village_no: credentials.villageNo,
          alley: credentials.alley,
          road: credentials.road,
          province: credentials.province,
          district: credentials.district,
          sub_district: credentials.subDistrict,
          province_name: credentials.provinceName,
          district_name: credentials.districtName,
          subdistrict_name: credentials.subDistrictName,
          postcode: credentials.postcode,
          country: credentials.country,
        })
        .then((response) => {
          console.log('API create address ::', response.data)
          if (response.data.isSuccess) {
            localStorage.setItem('currentBuildingInfo', JSON.stringify(response.data.data))
            context.commit('setCurrentBuildingInfo', JSON.stringify(response.data.data))
            resolve(response.data.data)
          } else {
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * View
   */

  /**
   * Updated
   */
  updateAddress(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .put(process.env.VUE_APP_ADDRESS_UPDATE + '/' + credentials.addressId, {
          addressTypeId: Number(credentials.addressType),
          group_number: credentials.groupNumber,
          title_deed_number: credentials.deedNumber || null,
          building: credentials.building || null,
          room_number: credentials.roomNumber || null,
          village_no: credentials.villageNo || null,
          alley: credentials.alley || null,
          road: credentials.road || null,
          province: Number(credentials.province),
          district: Number(credentials.district),
          sub_district: Number(credentials.subDistrict),
          province_name: credentials.provinceName,
          district_name: credentials.districtName,
          subdistrict_name: credentials.subDistrictName,
          postcode: credentials.postcode,
          country: credentials.country,
        })
        .then((response) => {
          console.log('API update address ::', response.data)
          if (response.data.isSuccess) {
            localStorage.setItem('currentBuildingInfo', JSON.stringify(response.data.data))
            context.commit('setCurrentBuildingInfo', JSON.stringify(response.data.data))
            resolve(response.data.data)
          } else {
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Delete
   */
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
